import fetch from '@/apis/request'

export default class Apis {
    // 分页查询所有吊篮
    static findAllDl(formData) {
        return fetch.get('/pc/hangingBasket/findAll', {
            params: formData
        })
    }
    // 添加吊篮
    static addDl(formData) {
        return fetch.post('/pc/hangingBasket/add', formData)
    }
    // 提交吊篮
    static submitDl(formData) {
        return fetch.post('/pc/hangingBasket/submit', formData)
    }
    // 编辑吊篮
    static updateDl(formData) {
        return fetch.post('/pc/hangingBasket/update', formData)
    }
    // 删除吊篮
    static delDl(data) {
        return fetch.post(`/pc/hangingBasket/del?id=${data}`)
    }
    // 拆除吊篮
    static removeDl(data) {
        return fetch.post(`/pc/hangingBasket/remove?id=${data}`)
    }
    // 查询吊篮详情
    static findByBasketId(data) {
        return fetch.get(`/pc/hangingBasket/findById`,{
            params: data
        })
    }
    // 修改 启用/报停 状态
    static changeDoStatus(data) {
        return fetch.post(`/pc/hangingBasket/createDoStatus`,data)
    }

    // 审批 启用/报停 状态
    static confirmDoStatus(id) {
        return fetch.post(`/pc/hangingBasket/confirm?id=${id}`)
    }
    // 查询 启用/报停 记录
    static getDoStatusRecord(data) {
        return fetch.get(`/pc/do-record/page`,{
            params: data
        })
    }

    // 修改检查通知人
    static changeCheckNotice(form) {
        return fetch.post('/pc/hangingBasket/changeCheckNotice', form)
    }
    // 查找属于角色总工程师和安全总监的用户
    static findCheckPerson(formData) {
        return fetch.get(`/pc/organization/findByRoleId`,{
            params: formData
        })
    }
    // 根据组织机构code和角色id获得所需要的的用户
    static findByDepartmentId(code) {
        if (typeof(code)=='string'){
            return fetch.get(`/pc/organization/findByDepartmentId?departmentCode=${code}`)
        }else{
            return fetch.get(`/pc/organization/findByDepartmentId`,{
                params: code
            })
        }
    }
    // 上传文件
    static uploadFile(form) {
        return fetch.post(`/admin/public/uploadFile`,form)
    }
}
